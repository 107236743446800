/* src/components/Sidebar.css */
.sidebar {
    width: 250px;
    background-color: #2c3e50;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
  
  .sidebar-logo {
    width: 0px;
    margin-bottom: 1rem;
  }
  
  nav ul {
    list-style: none;
    padding: 0;
  }
  
  nav ul li {
    margin: 1rem 0;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
  }
  
  nav ul li a.active {
    font-weight: bold;
    color: #3498db;
  }
  