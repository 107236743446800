/* src/components/Dashboard.css */
.dashboard {
    display: flex;
    height: 100vh;
  }
  
  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .content {
    flex: 1;
    padding: 20px; /* Agregar padding de 20px */
    overflow-y: auto;
  }