/* src/components/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #ecf0f1;
  }
  
  .search-bar {
    padding: 0.5rem;
    width: 200px;
  }
  
  .logout-button {
    padding: 0.5rem 1rem;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #c0392b;
  }
  